@import "../../../styles/variables.scss";

.full-screen {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background: $modal-background;
  z-index: 100;
  @media (max-width: 37.5em) {
    height: 200%;
  }

  &-inner {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 120rem;
    height: max-content;
    padding-bottom: 2rem;
    background: #fff;
    overflow-x: hidden;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;

    @media (max-width: 62em) {
      width: 100vh;
      height: 100%;
    }
    // @media (max-width: 37.5em) {
    //   height: 100%;
    // }
  }
}

.card {
  display: flex;
  flex: 1 0 100%;
  scroll-snap-align: start;
}

.carousel-btn {
  position: absolute;
  top: 2rem;
  width: 20px;
  height: 20px;
  z-index: 102;
  cursor: pointer;
  @media (max-width: 60em) {
    display: none;
  }
}
.btn-left {
  left: 2rem;
  border-top: 3px solid $orange-dark;
  border-left: 3px solid $orange-dark;
  transform: rotate(-45deg);
  &:hover {
    border-top: 3px solid $primary-orange;
    border-left: 3px solid $primary-orange;
  }
}

.btn-right {
  right: 6rem;
  border-top: 3px solid $orange-dark;
  border-right: 3px solid $orange-dark;
  transform: rotate(45deg);
  &:hover {
    border-top: 3px solid $primary-orange;
    border-right: 3px solid $primary-orange;
  }
}

.title-line {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-content: center;
  font-size: 2.4rem;
  background-color: #e0dfdf;
  padding: 1rem 3rem 1rem 6rem;
  gap: 4rem;

  @media (max-width: 60em) {
    padding: 1rem 3rem 1rem 2rem;
  }

  @media (max-width: 48em) {
    font-size: 2.4rem;
  }

  @media (max-width: 32em) {
    width: 100%;
    font-size: 2rem;
    flex-direction: column;
    gap: 0;
  }

  &-inner {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }

  &-title {
    font-weight: 600;
  }

  &-key {
    color: red;
  }
  & p {
    margin: 0;
  }
}
.song-body {
  height: 100%;
}

.song-body-text {
  width: 100%;
  height: 95vh;
  overflow: hidden;
  display: grid;
  grid-template-rows: repeat(auto-fill, 2.1rem);
  font-size: 2rem;
  column-gap: 2rem;
  grid-template-columns: repeat(3, auto);
  grid-auto-flow: column;
  background-color: #ffff;
  color: #222;
  padding: 2rem 2rem 2rem 4rem;
  white-space: pre-wrap;

  @media (max-width: 48em) {
    grid-template-columns: repeat(2, auto);
    padding: 1.6rem 1rem 1.6rem 2rem;
  }

  @media (max-width: 37.5em) {
    // display: flex;
    // flex-direction: column;
    // gap: 0;
    //margin-bottom: 0;
    //gap: 0.5rem;
    grid-template-columns: 1fr;
    grid-auto-flow: unset;
    height: 100%;
    //overflow-y: visible;
    padding: 1rem 2rem 4rem 4rem;
  }
  @media (max-width: 30em) {
    display: flex;
    flex-direction: column;
    font-size: 1.8rem;
    padding-left: 1.6rem;
  }

  & p {
    //line-height: 1;
    @media (max-width: 32em) {
      display: block;
      margin: 0;
      line-height: 18px;
      min-height: 18px;
    }
  }
}

.close-icon {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  font-size: 4rem;
  cursor: pointer;
  color: $orange-dark;
  &:hover {
    color: $primary-orange;
  }

  @media (max-width: 48em) {
    font-size: 3.2rem;
  }
}
