@import "../../../styles/variables.scss";

.select {
  //width: 100%;
  font-size: 1.8rem;
  color: $orange-dark;
  position: relative;
  & input {
    //width: 40rem;
    padding: 0.6rem 1rem 0.4rem 4rem;
    color: $orange-dark;
    border: 1px solid $primary-orange;
    border-radius: 4px;
  }
  &-search {
    position: absolute;
    left: 0.8rem;
    top: 1rem;
    font-size: 2.2rem;
  }
  &-close {
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
    font-size: 3rem;
    color: $orange-dark;
    z-index: 6;
    cursor: pointer;
    &:hover {
      color: $primary-orange;
    }
  }
  & input:focus {
    outline: 1px solid $primary-violet;
  }
  &-box {
    height: 0;
    width: 62rem;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fff;
    color: #777;
    //box-shadow: 0 1px 1px 2px $primary-shadow;
    overflow-y: auto;
    position: absolute;
    top: 4rem;
    left: 0;
    z-index: 5;
    transition: all 0.5s ease-out;

    &-item {
      padding-left: 4rem;

      &:hover {
        //background-color: $primary-shadow;
        background-color: $primary-light;
        cursor: pointer;
      }
    }
  }
}
.visable {
  width: 62rem;
  max-height: 20rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  background-color: #fff;
  //box-shadow: 0px 0px 10px 0px $primary-shadow;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  overflow-y: scroll;
  position: absolute;
  top: 4rem;
  left: 0;
  z-index: 5;
  transition: all 0.5s ease-out;
}
