@import "../../../styles/variables.scss";

.select {
  font-size: 1.8rem;
  color: $primary-orange;
  position: relative;
  width: 62rem;

  @media (max-width: 64em) {
    width: 100%;
  }

  @media (max-width: 48em) {
    width: 100%;
  }

  & input {
    width: 62rem;
    padding: 0.6rem 1rem 0.4rem 4rem;
    color: $orange-dark;
    border: 1px solid $primary-orange;
    border-radius: 4px;

    @media (max-width: 64em) {
      width: 100%;
    }

    @media (max-width: 48em) {
      width: 100%;
    }
  }

  &-icon {
    position: absolute;
    top: -0.5rem;
    right: 1rem;
    font-size: 3rem;

    &:hover {
      color: $orange-dark;
      cursor: pointer;
    }
  }

  &-search {
    position: absolute;
    left: 0.8rem;
    top: 0.3rem;
    font-size: 3rem;
  }
  &-close {
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
    font-size: 3rem;
    z-index: 6;
  }

  & input:focus {
    outline: 1px solid $primary-orange;
  }

  &-box {
    height: 0;
    width: 62rem;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0.1px 0 20px 0.1px #b9b9b9;
    overflow-y: auto;
    position: absolute;
    top: 4rem;
    left: 0;
    z-index: 5;
    transition: all 0.5s ease-out;

    &-item {
      padding-left: 4rem;
      font-size: 1.6rem;
      color: $orange-dark;

      &:hover {
        background-color: $primary-shadow;
        cursor: pointer;
      }
    }

    @media (max-width: 48em) {
      width: 100%;
    }
  }
}

.user-email {
  margin-left: 1rem;
  color: #555;
}

.visable {
  width: 62rem;
  max-height: 20rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  background-color: #fff;
  box-shadow: 0 1px 1px 2px $primary-shadow;
  overflow-y: scroll;
  position: absolute;
  top: 4rem;
  left: 0;
  z-index: 5;
  transition: all 0.5s ease-out;

  @media (max-width: 48em) {
    width: 100% !important;
  }
}

.added {
  margin-top: 2rem;
  display: grid;
  gap: 1.6rem;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 62em) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 48em) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 32em) {
    grid-template-columns: 1fr;
  }

  &-sb-user {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1.6rem;
    color: $orange-dark;
    border: 1px solid $primary-orange;
    border-radius: 4px;
    padding: 0.3rem;

    &-icon {
      position: absolute;
      top: 4px;
      right: 4px;
      font-size: 2.2rem;
      color: $primary-orange;

      &:hover {
        cursor: pointer;
        color: $orange-dark;
      }
    }
  }
}
