@import "./../../../../src/styles/variables.scss";

.left-sidebar {
  min-height: 65vh;
  width: 20rem;
  min-width: 20rem;
  margin: 0.9rem;
  margin-top: 2rem;
  padding-left: 0.5rem;

  .search-field {
    position: relative;

    .search-song {
      width: 100%;
      padding: 1rem;
      padding-left: 3rem;
      border: 1px solid $primary-orange;
      border-radius: 6px;
      font-size: 1.6rem;
      color: $primary-orange;
      margin-bottom: 2rem;
      outline: none;
    }

    .search-icon {
      position: absolute;
      color: $primary-orange;
      top: 1.3rem;
      left: 8px;
      font-size: 1.8rem;
      font-weight: 700;
    }
  }

  .topic {
    padding: 0.5rem;
    padding-left: 2rem;
    border: 1px solid $primary-orange;
    border-radius: 6px;
    font-size: 1.6rem;
    margin-bottom: 2rem;
    line-height: 1.2;

    &-field {
      width: 100%;
      border: none;
      background-color: $primary-light;
      padding-left: 1rem;
      color: $primary-orange;
      cursor: pointer;
      outline: none;
    }
  }

  ul.contents {
    padding-left: 0;
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    border-top: 1px solid $primary-orange;
    border-bottom: 1px solid $primary-orange;
    list-style: none;
    font-size: 1.6rem;

    li {
      padding: 0.2rem 0.4rem;

      &:hover {
        background-color: #fff;
      }

      a {
        color: #555;
        text-decoration: none;
      }
    }

    li.is-active {
      background-color: #fff;
      padding-left: 1rem;

      a {
        color: $orange-dark;
      }
    }
  }
}
