@import "../../../src/styles/variables.scss";

.sbooks {
  display: flex;
  flex-direction: column;
  padding-bottom: 4rem;
  //width: 80%;

  @media (max-width: 64rem) {
    width: 100%;
  }

  &-all {
    margin: 4rem 0;
    font-size: 1.8rem;
    color: $orange-dark;
    //border: 1px solid $primary-orange;
    border-radius: 4px;
    .label {
      color: #444;
    }
  }

  &-one {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    @media (max-width: 20em) {
      padding: 2px;
    }

    &:hover {
      background-color: $primary-shadow;
    }

    &-icons {
      display: flex;
      gap: 2.4rem;

      .icon {
        //padding: 0 4px 2px 4px;
        font-size: 3rem;
        color: $primary-orange;

        &:hover {
          //background-color: $primary-shadow;
          color: $orange-dark;
          cursor: pointer;
        }
      }
    }
  }
}
.addcolor {
  background-color: $primary-shadow;
}

.attributes {
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  padding: 1rem;
  padding-left: 4rem;
  border-bottom: 1px solid $orange-dark;
  @media (max-width: 20em) {
    padding-left: 1rem;
  }

  &-title {
    font-size: 1.6rem;
    color: #555;
    //text-decoration: underline;
    //font-weight: 700;
    //font-style: italic;

    // @media (max-width: 48em) {
    //   font-size: 2rem;
    // }

    & span {
      color: #333;
      font-size: 1.8rem;
      font-weight: 500;
    }
  }

  &-radio {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.8rem;
    gap: 2rem;
    color: #555;

    ///margin: 2.2rem 0;
    & input {
      width: 2rem;
    }

    &-item {
      display: flex;
      gap: 2rem;
    }
  }

  &-status {
    font-size: 1.6rem;
    //margin: 0rem 0 1rem 2rem;
    color: #444;

    & span {
      font-size: 1.6rem;
      color: #333;
      font-weight: 500;
    }
  }

  &-users {
    display: flex;
    gap: 1rem;
    font-size: 1.6rem;
    color: #555;
    //margin-top: 2rem;

    &-title {
      font-size: 1.6rem;
    }
    &-item {
      color: #333;
      font-weight: 500;
      display: inline-block;
      margin-right: 2rem;
    }
  }
}

.create-book {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 200%;
  background: $modal-background;
  z-index: 100;
}
.create-btn {
  width: fit-content;
  padding: 4px 25px;
  @media (max-width: 30em) {
    align-self: center;
  }
}

.create-book-box {
  //position: absolute;
  top: 20%;
  margin: 10rem auto;
  // left: 50%;
  // transform: translateX(-50%);
  width: 60%;
  background-color: $primary-dark;
  padding: 2rem 4rem;
  border-radius: 6px;
  //z-index: 1;

  @media (max-width: 70em) {
    width: 80%;
  }

  @media (max-width: 48em) {
    top: 5%;
    margin: 4rem auto;
    padding: 2rem;
    width: 100%;
  }

  @media (max-width: 26em) {
    width: 95%;
    padding: 1rem;
    margin: 4rem 0;
  }
}

.create-book-box .inputs {
  width: 62rem;
  //width: 100%;
  padding: 0.6rem 1rem 0.4rem 4rem;
  color: $primary-orange;
  border: 1px solid $primary-orange;
  border-radius: 4px;
  font-size: 2rem;

  &:focus {
    outline: 1px solid $primary-orange;
  }

  @media (max-width: 64em) {
    width: 100%;
  }

  @media (max-width: 48em) {
    width: 100%;
  }

  // @media (max-width: 26em) {
  //   width: 96%
  // }
}
.btn-box {
  display: flex;
  gap: 2rem;
}

.users-btn-box {
  display: flex;
  //flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
  //margin: 4rem 0;
  //justify-content: flex-end;
  border-bottom: none !important;

  @media (max-width: 26em) {
    width: 100%;
    margin-right: 1rem;
    // margin: 2rem 0;
  }
}

.create-box-btn {
  font-size: 1.6rem;
  padding: 0.4rem 2rem;
}

.create-box-label {
  font-size: 1.8rem;
  color: $orange-dark;
  font-style: italic;
  margin-top: 4rem;
}

// .sb-active {
//   background-color: $primary-shadow;
// }
