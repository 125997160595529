.hero {
  padding: 4rem;
  position: relative;
  //margin-bottom: 3rem;
  margin-top: 4rem;
  height: 60rem;

  @media (max-width: 48em) {
    padding-top: 0;
    margin-top: 2rem;
  }

  @media (max-width: 37.5em) {
    height: 54rem;
  }
  @media (max-width: 30em) {
    height: 45rem;
  }
  @media (max-width: 28em) {
    height: 36rem;
  }

  .hero-small-text {
    position: absolute;
    z-index: 5;
    color: #f97316;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 35px;
    background-color: rgba(249, 116, 22, 0.116);
    width: 170px;
    //border: 1px solid #f97316;
    border-radius: 20px;
    text-align: center;
    margin-bottom: 2rem;
  }

  h1 {
    position: absolute;
    top: 130px;
    z-index: 5;
    font-size: 70px;
    font-weight: 700;
    line-height: 1.1;
    letter-spacing: 0.8px;
    color: #1e3a8a;
    margin-bottom: 4rem;

    span {
      font-size: 72px;
      background: -webkit-linear-gradient(
        5deg,
        rgb(78, 70, 229) 30%,
        rgb(244, 114, 181),
        rgb(245, 159, 11)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media (max-width: 32em) {
        font-size: 40px !important;
      }
    }

    @media (max-width: 32em) {
      top: 90px;
      font-size: 40px !important;
    }
    @media (max-width: 22.5em) {
      font-size: 3rem !important;
    }
  }

  .create-button {
    position: absolute;
    z-index: 5;
    left: calc(50% - 145px);
    top: 48rem;
    border: none;
    border-radius: 50px;
    padding: 0.5rem 6rem;
    font-size: 3.6rem;
    font-weight: 500;
    letter-spacing: 1.5px;
    color: #ffffff;
    transition: all 0.2s;
    background: linear-gradient(to right, rgb(78, 70, 229), rgb(244, 114, 181));

    @media (max-width: 48em) {
      top: 50rem;
    }

    @media (max-width: 37.5em) {
      top: 44rem;
      font-size: 3rem;
    }

    @media (max-width: 30em) {
      top: 28rem;
      font-size: 2.6rem;
      left: calc(50% - 120px);
    }

    // @media (max-width: 22.5em) {
    //   top: 34rem;
    // }

    &::before {
      content: " ";
      z-index: -1;
      position: absolute;
      top: -5px;
      right: -5px;
      bottom: -5px;
      left: -5px;
      background: inherit;
      border-radius: inherit;
      opacity: 0.6;
      filter: blur(20px);
    }

    &:hover {
      //background: #4e46e5;
      background: linear-gradient(to right, rgb(78, 70, 229), rgb(78, 70, 229));
      transform: translateY(-3px);
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.6);
    }

    &:active {
      transform: translateY(0.4px);
    }
  }

  .hero-img-container {
    position: absolute;
    z-index: 1;
    top: 50px;
    right: 0;
    transform: rotate(10deg);

    .hero-img-group {
      display: flex;
      gap: 2rem;

      .hero-img-1 img {
        width: 220px;
        transform: translateY(50px);
        border-radius: 8px;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.6);

        @media (max-width: 62em) {
          display: none;
        }
      }

      .hero-img-2 img {
        width: 240px;
        transition: all 0.4s;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.6);
        border-radius: 8px;

        &:hover {
          transform: scale(103%);
          transition: all 0.4s;
        }

        @media (max-width: 22.5em) {
          //display: none;
        }
      }

      .hero-img-3 img {
        width: 200px;
        transform: translateY(240px);
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.6);
        border-radius: 8px;

        @media (max-width: 26em) {
          transform: translateY(40px);
        }

        @media (max-width: 22.5em) {
          display: none;
        }
      }

      .geo-form {
        position: absolute;
        width: 380px;
        height: 440px;
        border-radius: 22px;
        background-color: rgba(249, 116, 22, 0.116);
        top: 120px;
        right: 162px;
        z-index: -1;

        @media (max-width: 26em) {
          top: 20px;
        }
      }
    }
  }
}
