@import "../../../styles/variables.scss";

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #737373;
  background: $primary-light;
  font-size: 14px;
  margin-top: 2rem;
  padding: 2rem;

  & p {
    display: inline-block;
  }

  &-authors {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media(max-width: 48em) {
      flex-direction: column;
      gap: 2rem;
    }
  }
}

.footer_link {
  color: $primary-orange;
  font-weight: 500;

  &-hover {
    display: inline-block;
    color: $primary-orange;
    color: linear-gradient(to right, rgb(78, 70, 229), rgb(244, 114, 181));
    border-radius: 4px;
    padding: 1rem;
  }
}