@import "./../../../../styles/variables.scss";

.login-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: $modal-background;
  backdrop-filter: blur(2px);
  z-index: 100;

  &-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 32rem;
    background-color: $primary-light;
    padding: 2rem;
    border-radius: 10px;

    @media (max-width: 75em) {
      //flex-direction: column;
      width: 70%;
    }

    @media (max-width: 64em) {
      //flex-direction: column;
      width: 94%;
    }

    @media (max-width: 40em) {
      top: 14%;
      transform: translate(-50%, 0);
      height: 46rem;
    }
  }
}

.error-box {
  position: absolute;
  top: 0;
  left: 0;
  //transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary-light;
  padding: 2rem;
  border-radius: 10px;
}

.login-input {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2rem;
  padding: 2rem;

  @media (max-width: 40em) {
    flex-direction: column;
  }

  &-item {
    position: relative;
    width: 100%;
    &-eye {
      position: absolute;
      right: 0.5rem;
      top: 0.6rem;
      font-size: 2.4rem;
      color: $orange-dark;
      cursor: pointer;
      &:hover {
        color: $primary-orange;
      }
    }

    & input {
      width: 100%;
      padding: 10px;
      font-size: 1.8rem;
      color: #333;
      border: 1px solid $primary-orange;
      outline: none;
      border-radius: 4px;
      @media (max-width: 30em) {
        font-size: 2rem;
      }
    }
  }
}

.btn-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding-bottom: 4rem;
  border-bottom: 1px solid #666;

  @media (max-width: 40em) {
    padding-bottom: 6rem;
  }
  &-btn {
    font-size: 1.9rem;
    padding: 0.5rem 4rem;
  }
}

.btn-forget-password {
  position: absolute;
  bottom: 5px;
  right: 0;
  border: none;
  background: none;
  border-radius: 10px;
  //border-bottom: 1px solid #555;
  color: #222;
  padding: 5px 1.2rem;
  font-size: 1.2rem;
  align-self: flex-end;

  &:hover {
    background-color: #fff;
  }
}

.closeIcon {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  font-size: 3.8rem;
  color: $primary-orange;
}

.invite {
  display: flex;
  align-items: center;
  justify-content: center;

  .invite-text {
    display: inline-block;
    margin-right: 2rem;
    font-size: 1.8rem;
    text-align: center;
    @media (max-width: 30em) {
      font-size: 2rem;
    }

    &-btn {
      //margin-left: 2rem;
      padding: 0.5rem 2rem;
    }
  }
}

.error-text {
  display: inline-block;
  margin-right: 2rem;
  font-size: 2rem;
  color: $primary-orange;
  font-weight: 500;
}
