.song-editor {
  .wrapper {
    border: 1px solid #ccc;
  }
  .toolbar {
    background-color: #f6f6f6;
  }
  .editor {
    font-size: inherit;
    min-height: 400px;
    padding: 1rem;
  }
}
