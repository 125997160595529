@import "./../../../../src/styles/variables.scss";

header {
  color: #737373;
  padding-left: 4rem;

  @media (max-width: 48em) {
    padding: 0 2rem;
  }

  .img-logo {
    height: 6rem;
  }

  .text-logo {
    margin-left: 2rem;
    @media (max-width: 22.5em) {
      //transform: translateY(-1rem);
      margin-left: 1rem;
    }

    .title {
      color: #2a6bef;
      font-weight: 700;
      font-size: 1.5rem;
      margin-bottom: 0.3rem;
    }

    .sub-title {
      color: $primary-orange;
      font-weight: 700;
      font-size: 1.6rem;
      margin-bottom: 0;
      @media (max-width: 34em) {
        display: none;
      }
    }
  }

  .songbook-input-container {
    position: relative;
    width: 100%;

    .songbook-input {
      width: 100%;
      height: 4rem;
      padding: 8px 8px 8px 44px;
      color: #f89d5d;
      border-radius: 10px;
      background-color: #fff7ed;
      border: 1px solid $primary-orange;
      font-size: 1.8rem;
      outline: none;

      @media (max-width: 62em) {
        width: 98%;
      }
      @media (max-width: 22.5em) {
        width: 100%;
      }
    }

    .search,
    .enter {
      position: absolute;
      color: $primary-orange;
    }

    .search {
      font-size: 2.2rem;
      top: 0.9rem;
      left: 1.2rem;
    }

    .enter {
      font-size: 3.2rem;
      top: 4px;
      right: 12px;
      cursor: pointer;
    }

    .select-container {
      position: absolute;
      width: 98%;
      left: 1%;
      top: 4.1rem;
      background-color: $primary-light;
      -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
      z-index: 1;
      border-radius: 6px;
      max-height: 20rem;
      overflow-y: scroll;
      //border: 1px solid $primary-orange;

      .select-item {
        cursor: pointer;
        padding: 1px 0.5rem;
        padding-left: 2rem;
        font-size: 1.6rem;
        margin-bottom: 0;

        &:hover {
          background-color: #fff;
        }
      }

      .close {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 2.6rem;
        color: $primary-orange;
        cursor: pointer;
      }
    }
  }

  .login {
    padding: 1px 6px;
    font-size: 2rem;
  }
  .signIn {
    padding: 1px 26px;
    font-size: 1.8rem;
    margin-right: 2.4rem;
    @media (max-width: 22.5em) {
      padding: 1px 16px;
      margin-right: 0.5rem;
    }
  }

  .login-box {
    display: flex;
    //flex-direction: column;
    //padding: 10px 10px 0 0;
    //margin-left: 1rem;
    align-items: center;
    justify-content: center;

    // @media (max-width: 20em) {
    //   //flex-direction: row;
    //   align-content: center;
    //   justify-content: center;
    //   //transform: translateY(6rem);
    //   //margin-bottom: 6rem;
    // }
  }

  .user-email {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    font-size: 1.4rem;
    height: 100%;
    //align-content: center;
    justify-items: center;
    @media (max-width: 22.5em) {
      position: absolute;
      top: 5rem;
      left: 6rem;
      height: fit-content;
    }
  }
}

.header-position {
  position: relative;
}

.logedIn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  // @media (max-width: 22.5em) {
  //   transform: translateY(1rem);
  //   margin-top: 2rem;
  // }
}

.exit {
  color: $primary-orange;
  font-size: 4rem;
  cursor: pointer;
  margin-left: 2rem;
  margin-right: 1.2rem;
  @media (max-width: 22.5em) {
    margin-left: 0.5rem;
    font-size: 3rem;
    margin-right: 0;
  }

  &:hover {
    color: purple;
  }
}
