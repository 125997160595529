@import "../../../src/styles/variables.scss";

.pdata {
  margin-top: 4rem;
  padding-left: 1rem;
  font-size: 1.6rem;
  color: $orange-dark;
  @media (max-width: 37.5em) {
    margin-top: 0rem;
  }

  &-text {
    font-style: italic;
  }

  &-address {
    margin-bottom: 1rem;
    color: #777;
  }

  &-form {
    display: flex;
    flex-direction: row;
    gap: 6rem;
    @media (max-width: 37.5em) {
      flex-direction: column;
    }
    &-inputs {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    &-btn {
      font-size: 1.8rem;
      padding: 0.5rem 2.6rem;
      align-self: baseline;
      margin-top: auto;
      margin-bottom: 1.4rem;
    }

    & input {
      width: 30rem;
      margin-bottom: 1.4rem;
      padding: 0.6rem 1rem;
      color: $primary-orange;
      border: 1px solid $primary-orange;
      border-radius: 4px;
      @media (max-width: 30em) {
        width: 100%;
      }

      &:hover {
        //border: 2px solid $primary-orange;
        box-shadow: inset 2px -2px 1px $primary-orange;
      }
    }

    & label {
      width: 30rem;
      font-weight: 500;
      display: flex;
      gap: 1rem;
      @media (max-width: 30em) {
        width: 100%;
      }
      & span {
        color: red;
      }
    }
  }
  &-break {
    margin: 2rem 0;
    border-top: 1px solid #777;
  }
}

.message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  &-ok,
  &-error {
    display: inline-block;
    font-size: 2.2rem;
    //text-align: center;
    color: #777;
    margin-top: 2rem;
  }
  &-i-ok {
    font-size: 4rem;
    color: green;
  }
  &-i-error {
    font-size: 4rem;
    color: red;
  }
}
.eyeIcon {
  font-size: 2.2rem;
  cursor: pointer;
  justify-self: end;
  margin-left: auto;
  margin-right: 1rem;

  &:hover {
    color: $primary-orange;
  }
}
