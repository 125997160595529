@import "../../../src/styles/variables.scss";

.songbook {
  margin-top: 1rem;

  .title {
    font-size: 2.6rem;
    text-align: center;
    font-weight: 600;
    padding: 1rem 0;
    color: $orange-dark;
    background: -webkit-linear-gradient(
      75deg,
      rgba(78, 70, 229, 0.349),
      rgba(244, 114, 181, 0.377),
      rgba(245, 159, 11, 0.342)
    );

    h2 {
      animation: moveInLeft 1s ease-out;
    }
  }

  #rdw-wrapper-592
    > div.editor.rdw-editor-main
    > div
    > div
    > div
    > div
    > div:nth-child(5)
    > div {
    margin: 0;
  }

  .content-row {
    min-height: 70vh;
    position: relative;
    display: flex;
    width: 100%;

    .toggler {
      cursor: pointer;
      position: absolute;
      top: 0.2rem;
      font-size: 4rem;
      //z-index: 15;
      color: $primary-orange;
      padding: 0;
      border-radius: 3rem;
      line-height: 0;
      //background-color: #ffedd5;
      transition: all 0.1s;

      &.left_open {
        left: 22.2rem;
      }

      &.left_close {
        left: 0rem;
      }

      &.right_open {
        right: 20rem;
      }

      &.right_close {
        right: 0rem;
      }
    }

    .left,
    .right {
      overflow-y: auto;
      transition: all 0.1s;
      border-right: 1px solid $primary-shadow;
      border-left: 1px solid $primary-shadow;
      background-image: linear-gradient(172deg, #fff7ed 3%, #ffedd5 97%);
    }

    .left.open {
      width: 22rem;
      min-width: 22rem;
    }

    .right.open {
      width: 20rem;
      min-width: 20rem;
    }

    .left.close,
    .right.close {
      width: 0rem;
      min-width: 0rem;
    }

    .center {
      width: 100%;
      //min-width: 40rem;
      padding: 2rem 6rem;
      background-color: #fff;
      font-size: 2rem;

      @media (max-width: 48rem) {
        padding: 2rem 4rem;
      }

      @media (max-width: 32rem) {
        padding: 2rem 2rem;
      }
      @media (max-width: 22.5em) {
        margin-top: 4rem;
        padding: 1.2rem;
        //width: 20rem;
      }

      .tools {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        justify-content: flex-end;
        font-size: 2.4rem;
        font-weight: 600;

        @media (max-width: 48rem) {
          padding-right: 2rem;
        }

        @media (max-width: 32rem) {
          padding-right: 4rem;
        }

        .tool-icon {
          color: $primary-orange;
          background-color: transparent;
          border: 1px solid $primary-orange;
          border-radius: 5px;
          padding: 0 0.6rem;
          transition: all 0.2s;

          &:hover {
            background-color: $primary-dark;
          }
        }
      }

      .song {
        h3 {
          font-size: 2.4rem;
          font-weight: 500;
          color: $orange-dark;
          margin-bottom: 1rem;
        }

        input {
          font-size: 2rem;
          display: inline;
        }

        .data {
          //margin-bottom: 2rem;
          display: flex;
          gap: 3rem;
          color: #777;
          font-size: 1.6rem;
          justify-content: flex-end;
          align-items: center;

          @media (max-width: 48em) {
            gap: 1rem;
            justify-content: center;
          }
          @media (max-width: 22.5em) {
            display: grid;
            grid-template-columns: 1fr 1fr;
          }

          .key {
            @media (max-width: 22.5em) {
              grid-column: 1 / span 2;
            }
          }
          span {
            margin-left: 1rem;
            font-size: 1.6rem;
            color: $orange-dark;
          }

          input {
            width: 6rem;
            margin-left: 1rem;
          }
        }

        .text {
          display: flex;
          flex-direction: column;
          margin-bottom: 2rem;
          white-space: pre-wrap;

          & > div {
            //line-height: 0.3;

            p {
              //display: inline-block;
              min-height: 14px;
              margin: 0rem;
              font-size: 1.8rem;
              line-height: 1.3;

              @media (max-width: 48em) {
                min-height: 12px;
              }

              @media (max-width: 32em) {
                font-size: 1.8rem;
              }
            }
          }
        }
        .text-inner {
          display: flex;
          flex-direction: column;
        }

        .edit-action {
          margin-bottom: 2rem;
          display: flex;
          justify-content: flex-end;
        }
      }

      h3.no-song {
        font-size: 2.4rem;
        font-weight: 500;
        color: $orange-dark;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .left,
    .right {
      position: absolute;
      top: 0;
      z-index: 10;
      height: 100%;
      width: 0.5rem;
      min-width: 0.5rem;
    }

    .left {
      left: 0;
    }

    .right {
      right: 0;
    }
  }

  @media screen and (min-width: 992px) {
    .left,
    .right {
      position: relative;
    }

    .left {
      width: 22rem;
      min-width: 22rem;
    }

    .right {
      width: 20rem;
      min-width: 20rem;
    }
  }
}

.chords {
  color: blue;
  font-weight: 500;
}
.hide {
  opacity: 0;
  height: 0;
}
.chords-hide-btn {
  position: relative;
  background-color: inherit;
  border: 1px solid $primary-orange;
  border-radius: 4px;
  color: $primary-orange;

  &::before {
    content: "";
    position: absolute;
    top: 2rem;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $primary-orange;
    z-index: 200;
    transform: rotate(45deg);
  }

  &:hover {
    background-color: $primary-dark;
  }
}

.chords-show-btn {
  position: relative;
  background-color: inherit;
  border: 1px solid $primary-orange;
  border-radius: 4px;
  color: $primary-orange;

  &:hover {
    background-color: $primary-dark;
  }
}

.category-title {
  color: #333;
  font-size: 1.4rem;
}
.categories {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2rem;
  margin-bottom: 2rem;
  //justify-items: center;

  .category {
    font-size: 1.6rem;
    color: #777;
    display: flex;
    gap: 1rem;
    min-width: 10rem;
  }
}
.show-categories {
  display: flex;
  flex-direction: row;
  color: #555;
  gap: 1rem;
  font-size: 1.6rem;
  //align-items: flex-end;
  justify-content: end;
  margin-bottom: 2rem;
  &-item {
    text-decoration: underline;
    color: $orange-dark;
  }
  @media (max-width: 30em) {
    width: 100%;
    //align-items: start;
    justify-content: start;
  }
}

.key {
  position: relative;

  &-select {
    position: absolute;
    width: 10rem;
    height: fit-content;
    top: 5rem;
    right: 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #fff;
    border: 1px solid $primary-light;
    border-radius: 4px;
    box-shadow: 0.1px 0 20px 0.1px #b9b9b9;
    color: $orange-dark;
    align-items: center;
    z-index: 2;

    &-item {
      padding-left: 1rem;
      cursor: pointer;
      margin: 0;
      line-height: 1.8;

      &:hover {
        background-color: $primary-dark;
      }
    }
  }

  &-icon {
    margin-left: 1rem;
    font-size: 2rem;
  }
}
.input-box {
  font-size: 1.6rem;
  color: $orange-dark;
  border: 1px solid #b9b9b9;
  padding-left: 1rem;
  border-radius: 4px;
  outline: none;

  &:focus,
  &:active {
    outline: 4px solid $primary-dark;
  }
}
