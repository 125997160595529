.home {
  @media screen and (max-width: 1199px) {
    .hero .hero-img-container {
      opacity: .3;
    }
  }
  @media screen and (max-width: 768px) {
    .hero h1.title {
      font-size: 50px;
    }
    .hero h1.title span {
      font-size: 60px;
    }
  }
}