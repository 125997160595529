/*COLORS*/
$primary-light: rgb(255, 247, 237);
$primary-dark: rgb(255, 237, 213);
$primary-orange: rgb(249, 115, 22);
$primary-pink: rgb(244, 114, 181);
$primary-violet: rgb(78, 70, 229);
$primary-shadow: rgba(249, 116, 22, 0.116);
$orange-dark: rgb(172, 76, 8);
$modal-background: rgba(68, 43, 24, 0.644);

$title-font: 2.6rem;
$text-font: 2rem;
$chords-font: 2rem;
$key-words: 2rem;