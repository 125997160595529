@import "node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "./styles/variables.scss";

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  80% {
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInDown {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  80% {
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInDown {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

* {
  font-family: "Roboto", sans-serif;
}

html {
  font-size: 62.5%;

  @media (max-width: 22.5em) {
    font-size: 58%;
  }
}

a {
  cursor: pointer;
  color: #2a6bef;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.btn-mcolor {
  font-size: 1.8rem;
  padding: 0.6rem 3rem;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  background: linear-gradient(to right, rgb(78, 70, 229), rgb(244, 114, 181));
  color: #fff;
  transition: all 0.2s;

  &:hover {
    background: linear-gradient(to right, rgb(78, 70, 229), rgb(78, 70, 229));
    transform: translateY(-3px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
  }

  .btn-mcolor-inactive {
    font-size: 1.8rem;
    padding: 0.6rem 3rem;
    border-radius: 50px;
    border: none;
    background: #999;
    color: #fff;
  }

  &:active {
    transform: translateY(0.4px);
  }
}

.btn-mcolor-inactive {
  font-size: 1.8rem;
  padding: 0.6rem 3rem;
  border-radius: 50px;
  border: none;
  background: #999;
  color: #fff;
}

.btn-plain {
  padding: 3px 25px;
  border: none;
  border-radius: 50px;
  background-color: #fff7ed;
  font-size: 1.8rem;
  color: #f97316;
  font-weight: 500;
  transition: all 0.3s;

  &:hover {
    background: #fff7ed;
    box-shadow: -3px -3px 5px #4e46e599, 3px 3px 5px #f472b599;
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(0.4px);
  }
}

.tools-tooltip {
  font-size: 1.5rem;
}

.mrg-top {
  margin-top: 2rem;
}
.delete {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  //background: linear-gradient(rgba(172, 76, 8, 0.747), rgba(172, 76, 8, 0.712));
  background: $modal-background;

  &-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 20%;
    background-color: $primary-dark;
    padding: 2rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    @media (max-width: 64em) {
      width: 80%;
      height: 20%;
      gap: 2rem;
    }

    @media (max-width: 32em) {
      top: 30%;
      width: 80%;
      height: 30%;
      gap: 2rem;
    }

    @media (max-width: 26em) {
      top: 30%;
      width: 98%;
      height: 30%;
      gap: 2rem;
    }

    & span {
      font-weight: 500;
      color: purple;
    }

    &-message {
      color: $orange-dark;
      font-size: 1.8rem;

      @media (max-width: 64em) {
        //font-size: 2.4rem;
        text-align: center;
      }

      @media (max-width: 40em) {
        font-size: 2.4rem;
      }

      @media (max-width: 32em) {
        //margin-bottom: 1rem;
        font-size: 2rem;
      }
    }

    &-btns {
      display: flex;
      gap: 4rem;
    }

    &-btn {
      width: 12rem;
      padding: 0.2rem 1rem;

      @media (max-width: 32em) {
        width: 10rem;
        padding: 0.4rem 2rem;
      }
    }
  }
}
