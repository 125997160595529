@import "./../../../../src/styles/variables.scss";

.right-sidebar {
  min-height: 65vh;
  width: 18rem;
  min-width: 18rem;
  margin: 0.9rem;
  margin-top: 2rem;
  font-size: 1.6rem;
  text-align: center;
  font-weight: 500;
  border-bottom: 1px solid $primary-orange;

  &-1 {
    margin-bottom: 2rem;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .text {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4.6rem;
      //font-size: 2.2rem;
      font-size: 1.8rem;
      padding: 1rem 0;
    }
  }

  &-2 {
    color: $primary-orange;
    margin-bottom: 2rem;

    .text {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4rem;
      font-size: 1.8rem;
      //border-top: 1px solid $primary-orange;
      //border-bottom: 1px solid $primary-orange;
      padding: 1rem;
      margin-bottom: 1rem;
      background: #fff7ed;

      &:hover {
        cursor: pointer;
        background-color: #fff;
      }
    }
  }

  &-3 {
    border-top: 1px solid $primary-orange;
    //border-bottom: 1px solid $primary-orange;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;

    .note {
      font-size: 1.4rem;
      color: $primary-orange;
      font-weight: 400;
      text-align: center;
      padding: 1rem;

      &-absent {
        color: #999;
        font-weight: 400;
        margin-bottom: 2rem;
      }
    }

    .text {
      display: flex;
      align-items: center;
      width: 100%;
      height: 2rem;
      color: #999;
      text-align: start;
      width: 100%;
      height: 3rem;
      padding-left: 2rem;

      &:hover {
        background-color: #fff;
        cursor: pointer;
        border-radius: 50px;
      }

      .text-icon {
        margin-right: 1rem;
        color: $primary-orange;
        font-size: 2rem;
      }
    }
  }

  &-4 {
    //border-top: 1px solid $primary-orange;
    //border-bottom: 1px solid $primary-orange;
    color: #999;
    text-align: start;
    display: flex;
    flex-direction: column;

    .text {
      display: flex;
      align-items: center;
      width: 100%;
      height: 3rem;
      color: #999;
      padding-left: 2rem;

      &:hover {
        background-color: #fff;
        cursor: pointer;
        border-radius: 50px;
      }
    }

    .aditional-files {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.6rem;
      color: $primary-orange;
      font-weight: 500;
      padding: 1rem;
      letter-spacing: 1px;
      margin-bottom: 2rem;
      margin-top: 2rem;

      &:hover {
        //font-weight: 600;
        //text-decoration: underline;
        cursor: pointer;
        background-color: #fff;
      }
    }

    .text-icon {
      margin-right: 1rem;
      color: $primary-orange;
      font-size: 2rem;
    }
  }
}

.border {
  color: purple;
  border: 1px solid purple;
}
