@import "../../../src/styles/variables.scss";

.group-box {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0;
  // width: 100%;

  &-list {
    position: absolute;
    top: 4rem;
    left: 10%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    width: 40rem;
    max-height: 40rem;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0.1px 0 10px 0.1px #b9b9b9;
    overflow-y: scroll;
    cursor: pointer;
    @media (max-width: 30em) {
      left: 1rem;
      width: 100%;
    }
    @media (max-width: 18em) {
      left: 0rem;
      width: 26rem;
    }

    &-first {
      color: $orange-dark !important;
    }

    & p {
      line-height: 1.2;
      font-size: 1.8rem;
      line-height: 1;
      color: #777;
      padding: 0.5rem 1rem;
      margin: 0;
      &:hover {
        background-color: $primary-light;
        color: $orange-dark;
      }
    }
  }
  &-icon {
    font-size: 2rem;
    color: $primary-orange;
    //margin-right: 4rem;
  }
  &-input {
    width: 100%;
    cursor: pointer;
  }
}

.group-big-box {
  position: relative;
  display: flex;
  flex-direction: row;
  //align-items: center;
  //margin-bottom: 2.6rem;
  width: 100%;

  @media (max-width: 37.5em) {
    //flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
  }
  @media (max-width: 30em) {
    margin-top: 3rem;
    width: 100%;
  }
}
.collaborators {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 2.6rem;
  font-size: 1.6rem;
}

.slist {
  width: 100%;
  font-size: 1.8rem;
  color: #777;

  &-choose-group {
    border: none;
    color: $orange-dark;
    background-color: transparent;
    margin-bottom: 1rem;
    transition: all 0.3s;
    border-bottom: 1.6px solid $primary-orange;

    @media (max-width: 22.5em) {
      width: 80%;
    }
  }
  .group-btns {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    height: 50%;
    margin-left: 4rem;
    @media (max-width: 37.5em) {
      margin-left: 0;
      align-self: end;
    }
    @media (max-width: 30em) {
      position: absolute;
      top: -5rem;
      right: 0rem;
    }

    &-btn {
      font-size: 2rem;
      color: $orange-dark;
      padding: 2px 5px;
      border: none;
      background-color: inherit;
      &:hover {
        color: $primary-orange;
      }
    }
  }

  &-choose-group {
    padding-left: 4rem;
    outline: none;
    margin-right: 2rem;
    @media (max-width: 37.5em) {
      padding-left: 1rem;
      width: 80%;
    }

    &:active {
      border: none;
    }
  }

  .create-group-title {
    width: 62rem;
    padding: 0.6rem 1rem 0.4rem 2rem;
    color: $orange-dark;
    border: 1px solid $primary-orange;
    border-radius: 4px;
    font-size: 1.8rem;

    &:focus {
      outline: 1px solid $primary-orange;
    }

    @media (max-width: 60em) {
      width: 100%;
    }
  }

  &-btn-create {
    margin-bottom: 2rem;
  }
}
// .group-to-delete {
//   color: #777;
// }

.show-songs-margin {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.create-selection-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: $modal-background;
}

.settings-box {
  background-color: $primary-light;
  width: 80%;
  margin: auto auto;
  margin-top: 10rem;
  padding: 4rem;
  //margin-bottom: 8rem;
  border: 1px solid $primary-orange;
  border-radius: 6px;
  transition: all 5s;

  @media (max-width: 37.5em) {
    width: 98%;
    padding: 4rem 2rem;
  }
  @media (max-width: 30em) {
    margin-top: 2rem;
    padding-top: 0;
  }

  & .icon {
    font-size: 3.2rem;
    top: -10px;
    left: 54rem;

    @media (max-width: 62em) {
      left: 95%;
    }

    @media (max-width: 48em) {
      left: 90%;
    }
  }

  & p {
    margin-bottom: 1rem;
    margin-top: 2.8rem;
  }

  & input {
    width: 62rem;

    @media (max-width: 62em) {
      width: 100%;
    }
  }
}

.select {
  font-size: 1.8rem;
}

.hide-modal {
  display: none;
  transition: all 0.6s;
  //animation: moveInDown 3s infinite;
}

.group-name {
  font-size: 2rem;
  margin-left: 1rem;
  margin-bottom: 2.4rem;
}
