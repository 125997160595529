@import "../../../src/styles/variables.scss";

.cabinet {
  &-title {
    position: relative;
    text-align: center;
    padding: 0.4rem 0;
    color: $orange-dark;
    background: -webkit-linear-gradient(
      75deg,
      rgba(78, 70, 229, 0.349),
      rgba(244, 114, 181, 0.377),
      rgba(245, 159, 11, 0.342)
    );

    h2 {
      font-size: 2rem;
      font-weight: 500;
      animation: moveInLeft 1s ease-out;
    }
    &-close {
      position: absolute;
      top: 1px;
      right: 4px;
      font-size: 3.5rem;
      color: $primary-orange;
      transition: all 0.3s;

      &:hover {
        cursor: pointer;
        color: $orange-dark;
      }
    }
  }

  &-btns {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: $primary-shadow;
    @media (max-width: 30em) {
      //height: 10rem;
    }
  }

  .tab-title {
    font-size: 1.8rem;
    text-align: center;
    width: 20rem;
    padding: 0.8rem 0;
    border-bottom: 1.5px solid $primary-orange;
    border-bottom-right-radius: 4px;
    border-radius: 2px;
    color: #777;
    //border-bottom-left-radius: 4px;

    &:hover {
      cursor: pointer;
    }
  }

  .empty {
    width: stretch;
  }

  .current {
    border: 1.5px solid $primary-orange;
    border-radius: 2px;
    border-bottom: none;
    background-color: $primary-light;
    color: $orange-dark;

    &:hover {
      cursor: auto;
    }
  }
}

.tabs {
  padding: 4rem 2rem;
  @media (max-width: 30em) {
    padding: 2rem 1rem;
  }
}
