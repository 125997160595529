@import "../../../styles/variables.scss";

.show-slist {
  margin-top: 1rem;
  width: 80rem;

  @media (max-width: 62em) {
    width: 100%;
  }

  &-title {
    display: grid;
    grid-template-columns: 3fr 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    font-size: 2rem;
    font-family: 500;
    font-style: italic;
    color: $orange-dark;
    border-bottom: 1px dotted $orange-dark;
    margin-bottom: 0.5rem;
    @media (max-width: 30em) {
      font-size: 1.8rem;
      display: grid;
      grid-template-columns: 2fr 1fr;
    }
    & p {
      margin: 0;
    }
    &-name {
      @media (max-width: 30em) {
        grid-area: 1 / span 2;
      }
    }
    &-date {
      font-size: 1.6rem;
    }
  }

  &-list {
    margin-left: 2rem;
  }

  &-item {
    font-size: 1.8rem;
    color: #666;
    padding-left: 4px;

    & span {
      font-style: italic;
      color: $orange-dark;
      font-weight: 500;
      margin-left: 4rem;
    }
  }

  &-icons {
    display: flex;
    align-items: center;
    gap: 1.4rem;
    justify-self: end;

    &-item {
      &:hover {
        cursor: pointer;
        color: $primary-orange;
      }
    }
  }
}

.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
}
