@import "../../../../src/styles/variables.scss";

.choose {
  border: 0.6px solid $primary-orange;
  background-color: $primary-light;
  width: 66rem;
  padding: 2rem;
  margin-top: 2rem;
  width: 66rem;
  margin: 0 auto;
  margin-top: 4rem;
  @media (max-width: 62em) {
    width: 100%;
  }
  &-input {
    display: flex;
    flex-direction: column;
    font-size: 1.8rem;
    & input:focus {
      outline: 1px solid $primary-violet;
    }

    & label {
      color: #777;
      font-weight: 400;
      font-size: 1.6rem;
      margin-bottom: 0.5rem;
    }

    & input {
      color: $orange-dark;
      width: 100%;
      border: 1px solid $primary-orange;
      border-radius: 4px;
      margin-bottom: 1rem;
      padding: 0.4rem 3rem 0.4rem 1.6rem;
    }
  }
  &-song {
    position: relative;
  }
  &-btn {
    position: absolute;
    width: 8rem;
    height: 3rem;
    top: 2px;
    right: 2px;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 4px;
    border: none;
    background-color: transparent;
    transition: all 0.3s;
    &-icon {
      color: $orange-dark;
      width: 3rem;
      height: 3rem;
      &:hover {
        color: $primary-orange;
      }
    }
  }

  &-btns {
    display: flex;
    justify-content: end;
    gap: 2rem;
    margin-top: 4rem;

    & button {
      font-size: 1.6rem;
      padding: 0.4rem 2rem;
    }
  }
}

.chosen-songs {
  font-size: 1.8rem;
  color: #777;
  padding-left: 4rem;

  &-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid transparent;
    transition: all 0.3s;

    &:hover {
      border-bottom: 1px dotted $orange-dark;
    }

    & p {
      margin-bottom: 0;
      color: #777;
    }
  }

  &-icons {
    display: flex;
    gap: 1rem;
    font-size: 2.2rem;
    color: $orange-dark;

    .icon {
      transition: all 0.3s;

      &:hover {
        color: $primary-orange;
        cursor: pointer;
      }
    }
  }
}
.visable {
  width: 62rem;
  max-height: 20rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  overflow-y: scroll;
  position: absolute;
  top: 4rem;
  left: 0;
  z-index: 5;
  transition: all 0.5s ease-out;
}
