.App {
  background-image: linear-gradient(
    172deg,
    #fff7ed 20%,
    #ffedd5 43%,
    #fff 43% 100%
  );
  min-height: 100vh;
  padding-top: 10px;

  @media (max-width: 37.5em) {
    background-image: linear-gradient(
      172deg,
      #fff7ed 20%,
      #ffedd5 40%,
      #fff 40% 100%
    );
  }
}

.container_custom {
  max-width: 120rem;
  margin: 0 auto;
  overflow-x: hidden;
}
