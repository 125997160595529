@import "./../../../../src/styles/variables.scss";

@keyframes list {
  0% {
    opacity: 0;
    transform: translateY(20rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-out {
  animation: list 1s ease-in;
}

.feature {
  max-width: 120rem;
  margin: 0 auto;
  margin-bottom: 18rem;
  margin-top: 8rem;

  //padding: 0 2.4rem;
  @media (max-width: 48em) {
    margin-top: 2rem;
  }

  @media (max-width: 36em) {
    margin-top: 2rem;
  }
  @media (max-width: 30em) {
    max-width: 100%;
    margin: 0;
    margin-bottom: 4rem;
  }
  @media (max-width: 20em) {
    max-width: 100%;
  }

  &-features {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
    width: 100%;
    margin-top: 4rem;

    @media (max-width: 62em) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 30em) {
      grid-template-columns: 1fr;
      row-gap: 10rem;
      justify-content: center;
    }
    @media (max-width: 20em) {
      row-gap: 8rem;
    }
  }

  &-imgbox {
    position: relative;
    display: flex;
    width: 38rem;
    height: 38rem;
    justify-content: center;
    align-items: center;
    justify-self: center;
    z-index: 5;

    @media (max-width: 62em) {
      grid-column: span 2;
    }
    // @media (max-width: 30em) {
    //   transform: translateY(-3rem);
    // }
    @media (max-width: 30em) {
      //grid-column: span 1;
      height: fit-content;
      transform: translateY(0rem);
    }

    &-img {
      width: 24rem;

      @media (max-width: 75em) {
        width: 20rem;
      }
      @media (max-width: 37.5em) {
        width: 16rem;
      }
      @media (max-width: 30em) {
        width: 14rem;
      }
      @media (max-width: 22.5em) {
        width: 10rem;
      }
    }

    &-motion-circle {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      width: 38rem;
      height: 38rem;
      border: 1px solid rgb(249, 115, 22);
      border-radius: 50%;
      position: absolute;
      animation-name: spin;
      animation-duration: 18000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;

      @media (max-width: 75em) {
        width: 30rem;
        height: 30rem;
      }

      @media (max-width: 37.5em) {
        width: 26rem;
        height: 26rem;
      }
      @media (max-width: 30em) {
        width: 22rem;
        height: 22rem;
      }
      @media (max-width: 22.5em) {
        width: 18rem;
        height: 18rem;
      }

      @keyframes spin {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
        }
      }
    }
  }

  &-list {
    list-style: none;
    font-size: 2.4rem;
    line-height: 2.6;
    justify-content: center;
    transition: all 1s;
    z-index: 10;
    @media (max-width: 87.5em) {
      font-size: 2rem;
    }
    @media (max-width: 52em) {
      line-height: 1;
      padding-left: 0rem;
    }

    & li {
      display: flex;
      align-items: baseline;
      @media (max-width: 30em) {
        width: 100%;
        padding: 0;
      }
    }

    &-1 {
      @media (max-width: 48em) {
        align-items: center;
      }
      @media (max-width: 20em) {
        justify-self: unset;
      }
    }

    &-2 {
      padding-left: 8rem;

      @media (max-width: 75em) {
        padding-left: 2rem;
      }

      @media (max-width: 62em) {
        grid-column: 2 / 3;
        grid-row: 1 / 1;
      }

      @media (max-width: 30em) {
        grid-column: 1 / 1;
        grid-row: 3 /3;
      }
    }

    &-icon {
      width: 4rem;
      height: 4rem;
      //background: rgba(249, 116, 22, 0.116);
      padding: 8px;
      margin-right: 1.8rem;
      border-radius: 50%;
      margin-right: 8px;
      transform: translateY(8px);
      color: rgb(249, 115, 22);
      font-size: 2rem;
    }
  }
}

.note-style {
  width: 4rem;
  height: 4rem;
  background: rgba(249, 116, 22, 0.116);
  padding: 0.8rem;
  border-radius: 50%;
  color: rgb(249, 115, 22);
  z-index: 5;
  @media (max-width: 30em) {
    width: 3rem;
    height: 3rem;
    padding: 0.4rem;
  }
}

.music-note_1,
.music-note_2,
.music-note_3,
.music-note_4 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff7ed;
  width: 7.4rem;
  height: 7.4rem;
  border-radius: 50%;
  z-index: 5;
  @media (max-width: 30em) {
    width: 6rem;
    height: 6rem;
    //padding: 0.2rem;
  }
}

.music-note_1 {
  justify-self: start;
  align-self: flex-start;
  transform: rotate(310deg);
}

.music-note_2 {
  justify-self: end;
  transform: rotate(40deg);
}

.music-note_3 {
  align-self: end;
  transform: rotate(220deg);
}

.music-note_4 {
  justify-self: end;
  align-self: flex-end;
  transform: rotate(120deg);
}

.box-open {
  position: relative;
  width: 90rem;
  height: 20rem;
  margin: 0 auto;
  background: rgba(249, 116, 22, 0.08);
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 6rem;
  margin-top: 14rem;
  outline: none;

  @media (max-width: 52em) {
    width: 100%;
    margin-top: 8rem;
  }

  @media (max-width: 37.5em) {
    margin-top: 4rem;
    border-radius: 0;
    padding: 1rem;
  }
  @media (max-width: 30em) {
    margin-top: 6rem;
  }

  &-btn {
    //position: absolute;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 4rem;
    width: 70rem;
    height: 6rem;
    background: linear-gradient(
      to right,
      rgb(78, 70, 229),
      rgb(244, 114, 181),
      rgb(245, 159, 11)
    );
    border-radius: 100px;
    z-index: 2;

    // @media (max-width: 48em) {
    //   width: 50rem;
    // }

    @media (max-width: 48em) {
      width: 96%;
    }

    .search-icon {
      position: absolute;
      font-size: 2.6rem;
      top: 1.5rem;
      left: 1rem;
      color: $primary-orange;
    }

    &::before {
      content: "";
      width: 100%;
      z-index: -1;
      position: absolute;
      top: -5px;
      right: -5px;
      bottom: -5px;
      left: -5px;
      background: inherit;
      border-radius: inherit;
      opacity: 0.6;
      filter: blur(20px);
    }

    &-input {
      width: 100%;
      height: 100%;
      font-size: 2.2rem;
      border-radius: 10px;
      padding: 1.4rem;
      padding-left: 4rem;
      color: rgb(115, 115, 115);
      border: none;
      outline: none;

      @media (max-width: 48em) {
        width: 100%;
      }
      @media (max-width: 30em) {
        font-size: 2rem;
      }
    }

    &-button {
      position: absolute;
      top: 0.7rem;
      right: 1.4rem;
      padding: 0.8rem 2.2rem;
    }
  }
}

.box-open-text-1 {
  font-size: 3.5rem;
  font-weight: 500;
  margin-bottom: 4rem;

  background: -webkit-linear-gradient(
    5deg,
    rgb(70, 63, 201) 30%,
    rgb(248, 101, 177),
    rgb(245, 159, 11)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 48em) {
    margin-bottom: 2rem;
  }

  @media (max-width: 37.5em) {
    margin-bottom: 0;
    font-size: 3rem;
    padding: 0 2rem;
  }
  @media (max-width: 30em) {
    margin-bottom: 0;
    transform: translateY(2rem);
  }
}

.box-open-text-2 {
  font-size: 2rem;
  font-weight: 300;
  margin-top: 1.2rem;
  color: rgb(249, 115, 22);
  @media (max-width: 30em) {
    margin-bottom: 0;
    transform: translateY(2rem);
  }
}

.icon-1,
.icon-2,
.icon-3,
.icon-4,
.icon-5 {
  font-size: 20px;
  position: absolute;
  opacity: 0.5;
}

.icon-1 {
  top: 15rem;
  left: 2rem;
  transform: rotate(-20deg);
}

.icon-2 {
  top: 4rem;
  right: 6rem;
  transform: rotate(20deg);

  @media (max-width: 62em) {
    top: 6rem;
  }

  @media (max-width: 22.5em) {
    right: 4rem;
  }
}

.icon-3 {
  top: 10rem;
  right: 25rem;
  transform: rotate(-20deg);

  @media (max-width: 62em) {
    right: 15rem;
  }

  @media (max-width: 48em) {
    right: 3rem;
    top: 15rem;
  }
}

.icon-4 {
  top: 4rem;
  left: 6rem;
  transform: rotate(30deg);

  @media (max-width: 62em) {
    top: 6rem;
  }

  @media (max-width: 22.5em) {
    left: 4rem;
  }
}

.icon-5 {
  top: 10rem;
  left: 25rem;
  transform: rotate(-160deg);

  @media (max-width: 62em) {
    left: 15rem;
  }

  @media (max-width: 62em) {
    left: 16rem;
    top: 7rem;
  }

  @media (max-width: 22.5em) {
    display: none;
  }
}
.title-for-options {
  display: flex;
  justify-content: center;
  margin-top: 10rem;
  padding: 8rem;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: rgb(115, 115, 115);

  @media (max-width: 37.5em) {
    margin-top: 0rem;
    padding: 6rem;
  }
  @media (max-width: 30em) {
    padding: 4rem;
  }
  @media (max-width: 22.5em) {
    margin-top: 6rem;
    padding: 4rem;
  }
}

.options {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4rem;
  justify-items: center;
  transition: all 1s;
  padding: 0 2rem;
  height: fit-content;

  @media (max-width: 56em) {
    gap: 2rem;
  }

  @media (max-width: 37.5em) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  @media (max-width: 30em) {
    grid-template-columns: 1fr;
    margin-bottom: 14rem;
  }

  &-background {
    width: 100%;
    background-image: linear-gradient(
      #fff 50%,
      rgb(255, 247, 237),
      rgb(255, 237, 213) 75%,
      #fff 75% 100%
    );
  }

  &-icon {
    font-size: 4.8rem;
    font-weight: 700;
    background-color: rgba(249, 116, 22, 0.089);
    color: rgb(249, 115, 22);
    padding: 1rem;
    border-radius: 50%;
    margin-bottom: 4px;
  }

  &-box {
    width: 80%;
    padding: 1rem;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 56em) {
      width: 100%;
    }

    & p {
      font-size: 1.8rem;
      font-weight: 500;
      color: $orange-dark;
      text-align-last: center;
      line-height: 1.4;
      & span {
        color: rgb(115, 115, 115);
        font-size: 1.6rem;
        font-weight: 400;
      }
    }

    .plan {
      list-style: none;
      color: rgb(115, 115, 115);
      font-size: 1.6rem;

      & li {
        display: flex;
        align-items: center;
        font-weight: 400;
        & .icon {
          margin-right: 1rem;
        }
      }
      &-item {
        color: $primary-orange;
      }
    }
  }
}
.options-box-one {
  @media (max-width: 37.5em) {
    grid-column: 1 / span 2;
    width: 30rem;
    margin: 0 auto;
  }
  @media (max-width: 30em) {
    grid-column: 1 / span 1;
    width: 100%;
  }
}

.select-container_two {
  position: absolute;
  width: 100%;
  left: 0rem;
  top: 6rem;
  border-radius: 6px;
  //padding-left: 2rem;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  z-index: 1;
  //border: 1px solid $primary-orange;

  .select-item_two {
    cursor: pointer;
    padding: 0.1rem;
    padding-left: 4rem;
    font-size: 1.6rem;
    margin-bottom: 0;
    text-align: left;

    &:hover {
      background-color: $primary-dark;
    }
  }

  .close_two {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 2.6rem;
    color: $primary-orange;
    cursor: pointer;
  }
}
.action-btn {
  position: relative;
  margin-top: 8rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1 span / 3;
  justify-self: center;
  @media (max-width: 37.5em) {
    grid-column: 1 / span 2;
    justify-self: center;
  }
  @media (max-width: 30em) {
    height: 0rem;
    grid-column: 1 / span 1;
    transform: translateY(-3rem);
  }
}
.register-button {
  position: absolute;
  width: 34rem;
  z-index: 5;
  top: 0rem;
  border: none;
  border-radius: 50px;
  padding: 0.5rem 6rem;
  font-size: 2.2rem;
  font-weight: 500;
  letter-spacing: 1.5px;
  color: #ffffff;
  transition: all 0.2s;
  background: linear-gradient(to right, rgb(78, 70, 229), rgb(244, 114, 181));
  @media (max-width: 22.5em) {
    font-size: 2rem;
    width: 24rem;
    padding: 0.5rem 1rem;
  }

  &::before {
    content: " ";
    z-index: -1;
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    background: inherit;
    border-radius: inherit;
    opacity: 0.6;
    filter: blur(20px);
  }

  &:hover {
    //background: #4e46e5;
    background: linear-gradient(to right, rgb(78, 70, 229), rgb(78, 70, 229));
    transform: translateY(-3px);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.6);
  }

  &:active {
    transform: translateY(0.4px);
  }
}
